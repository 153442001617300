// External
import React from 'react';

// Material UI
import CircularProgress from '@material-ui/core/CircularProgress';

// Owned
import CheckSuccess from './CheckSuccess';
import CheckWarning from './CheckWarning';

export default function CheckResult(props) {
    if (props.isLoading) {
        return <CircularProgress color="primary" />
    }

    if (props.isExtensionEnabled) {
        return <CheckSuccess/>
    }

    return <CheckWarning subtitleKey={props.warningSubtitleKey} />
}
