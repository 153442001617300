// External
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Material UI
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress';

// Amplify
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';

// Owned
import Authenticator from './Auth/Authenticator';
import MessageQueue from './Shared/MessageQueue';
import Check from './Check/Check';
import awsconfig from '../aws-exports';
import '../App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import BrandHeader from './Shared/BrandHeader';

const queue = new MessageQueue();

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#00DA77",
    },
    secondary: {
      main: "#F0EB6A",
    },
    background: {
      default: "#35363A",
    },
    text: {
      primary: "#ECFDEB",
    }
  },
  typography: {
    fontFamily: [
      'Instrument Sans',
    ]
  },
  overrides: {
    MuiButton: {
      root: {
        height: 48,
        border: "2px solid #ECFDEB", // text primary
        borderRadius: 10,
        boxShadow: 'none',
        width: '100%',
      },
      text: {
        fontSize: 14,
        letterSpacing: 0.42,
        textTransform: 'capitalize',
        fontWeight: 600,
      },
      containedSecondary: {
        color: "#ECFDEB",
        backgroundColor: "#35363A",
        '&:hover': {
          backgroundColor: '#606060',
        }
      },
    },
    MuiTextField: {
      root: {
        '& .MuiInputBase-root': {
          backgroundColor: "#ECFDEB", // text primary
          borderRadius: '10px',
        },
        '& .MuiFormLabel-root': {
          color: `#35363A !important`, // background default
          opacity: 0.5,
          fontWeight: 500,
          transform: 'translate(24px, 16px) scale(1)',
        },
        '& .MuiInputBase-input': {
          padding: '15px 24px',
          height: '18px',
          borderRadius: '10px',
          color: `#35363A !important`, // background default
        },
        '& .MuiFormLabel-root.MuiInputLabel-filled.MuiInputLabel-shrink': {
          transform: 'translate(12px, 2px) scale(0.75)',
        },
        '& .MuiFilledInput-root.MuiFilledInput-underline:after': {
          content: 'unset',
        },
        '& .MuiFilledInput-root.MuiFilledInput-underline:before': {
          content: 'unset',
        },
      },
    }
  }
});

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function Wrapper(Component) {

  const WrapperComponent = () => {
    const fromExtensionQueryParam = useQuery().get('fromExtension');
    const fromOptionsQueryParam = useQuery().get('options');
    const fromOnboardQueryParam = useQuery().get('fromOnboard');

    const [loading, setLoading] = useState(true);
    const [authenticated, setAuthenticated] = useState(false);

    const signOut = () => {
      Auth.signOut()
        .then(() => {
          setAuthenticated(false);
        })
        .catch((e) => {

        })
    }

    useEffect(() => {
      if (fromExtensionQueryParam === "true" || fromOnboardQueryParam === "true") {

        Auth.currentAuthenticatedUser().then(() => {

          Auth.signOut()
            .then(() => {
              setAuthenticated(false);
              setLoading(false);
              window.location.reload(false);
            });

        }).catch(() => {
          setAuthenticated(false);
          setLoading(false);
        });

      } else {
        Auth.currentAuthenticatedUser()
          .then(() => {
            setAuthenticated(true);
            setLoading(false);
          })
          .catch(() => {
            setAuthenticated(false);
            setLoading(false);
          });
      }
    }, [fromExtensionQueryParam, fromOptionsQueryParam, fromOnboardQueryParam]);

    // This is triggered when our comm script is injected successfully, so we can check the message queue for pending authentication requests
    if (fromExtensionQueryParam === "true") {
      document.addEventListener('saapiloaded', function () {
        let message = queue.getMessage();
        while (message) {
          document.dispatchEvent(new CustomEvent('superAgent_sdte', { detail: { message } }))
          // eslint-disable-next-line no-undef
          if (typeof sdte !== 'undefined') {
            // eslint-disable-next-line no-undef
            sdte(message);
          }
          message = queue.getMessage();
        }
      });
    }

    if (loading) {
      return (
        <CircularProgress />
      )
    } else {
      return (
        <ThemeProvider theme={theme}>
        <div className="App">
          <CssBaseline />
          <BrandHeader />
          {authenticated ?
            <Router>
              <Switch>
                <Route path="/check">
                  <Check />
                </Route>
                <Route>
                  <Component signOut={signOut} />
                </Route>
              </Switch>
            </Router>
            :
            <div>
              <Router>
                <Switch>
                  <Route exact path="/">
                    <Authenticator setAuthenticated={setAuthenticated} initialState={AuthState.SignIn} queue={queue} />
                  </Route>
                  <Route path="/signin">
                    <Authenticator setAuthenticated={setAuthenticated} initialState={AuthState.SignIn} queue={queue} />
                  </Route>
                  <Route path="/signup">
                    <Authenticator setAuthenticated={setAuthenticated} initialState={AuthState.SignUp} queue={queue} />
                  </Route>
                  <Route path="/resetpassword">
                    <Authenticator setAuthenticated={setAuthenticated} initialState={AuthState.ForgotPassword} queue={queue} />
                  </Route>
                  <Route path="/check">
                    <Check />
                  </Route>
                </Switch>
              </Router>
              {awsconfig.aws_user_pools_id === 'eu-west-1_iv6CkI7wU' && (
                  <>
                    <br/>
                    <br/>
                    This is a development environment. Accounts created here will not work with the Super Agent
                    extension.
                  </>
              )}
            </div>

          }
        </div>
        </ThemeProvider>
      )
    }
  }

  return WrapperComponent;
}
