// External
import React, { useState, useEffect, useCallback } from 'react';

import Container from '@material-ui/core/Container';

// Owned
import { theme, useStyles } from '../styles';
import CheckResult from './CheckResult';
import { BrowserHelper } from '../Shared/BrowserHelper';
import { CheckFakeDialog } from './CheckFakeDialog';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

export default function Check() {
    const { t } = useTranslation();
    const classes = useStyles(theme);
    const [isLoading, setIsLoading] = useState(true);
    const [isExtensionEnabled, setExtensionEnabled] = useState(false);

    const isiOS = BrowserHelper.isiOS(navigator.userAgent);
    const isMobileSafari = BrowserHelper.isMobileSafari(navigator.userAgent);

    useEffect(() => {
        setTimeout(() => {

            const existingTestCookiePopup = document.querySelector("div#check-test-dialog");

            setExtensionEnabled(existingTestCookiePopup === null);
            setIsLoading(false);

        }, 5000);
    }, []);

    const onPopupClose = useCallback(() => {
        setExtensionEnabled(true);
        setIsLoading(false)
    }, [])

    return (
        <Container component="main" maxWidth="xs" className={classes.container}>
            <div className={classes.checkPaper}>
                <CheckResult
                    isLoading={isLoading}
                    isExtensionEnabled={isExtensionEnabled}
                    warningSubtitleKey={isiOS && !isMobileSafari ? "test_extension.warning.not_ios_safari" : "test_extension.warning.subtitle"}
                />
                {!isLoading && (
                    <Typography className={classes.testExtensionFooter}>
                        { t('test_extension.mailus') } <a className={classes.testExtensionFooterMail} href="mailto:hello@super-agent.com">hello@super-agent.com</a>
                    </Typography>
                )}
                <CheckFakeDialog close={onPopupClose} />
            </div>
        </Container>
    )
}
