import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#002F44",
    },
    secondary: {
      main: "#FDC737",
    },
    text: {
      primary: "#002F44",
      secondary: "#57636E"
    }
  },
  typography: {
    fontFamily: [
      'Nirmala'
    ]
  }
});

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
  },
  checkPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 30,
    paddingRight: 30,
    minHeight: 'calc(100svh - 94px - 30px)',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  imgHeader: {
    margin: "20px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  testExtensionContent: {
    marginTop: "auto",
  },
  testExtensionTitle: {
    fontWeight: 600,
    fontSize: 26,
    lineHeight: '32px',
    letterSpacing: 0.78,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    whiteSpace: "pre-line",
    marginTop: 20,
  },
  testExtensionSubtitle: {
    fontWeight: 500,
    fontSize: 14,
    letterSpacing: 0.14,
    lineHeight: '18px',
    marginTop: "5px",
    color: theme.palette.text.primary,
    whiteSpace: "pre-line"
  },
  testExtensionFooter: {
    marginTop: "auto",
    fontSize: "12px !important",
    color: theme.palette.text.primary,
    whiteSpace: "pre-line",
  },
  findExtensionSettingsToolbar: {
    paddingTop: '25px',
    paddingBottom: '25px',
    paddingLeft: '30px',
    paddingRight: '30px',
    marginTop: '60px',
    borderRadius: '30px',
    width: 'auto',
    maxWidth: "100%",
    color: theme.palette.text.primary,
    border: '2px solid',
    borderColor: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  findExtensionSettingsToolbarIcon: {
    alignSelf: 'flex-start',
    fill: 'transparent',
    stroke: 'currentColor',
  },
  findExtensionSettingsToolbarText: {
    textAlign: 'left',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: 0.14,
    fontWeight: 500,
  },
  congratulationsConfettiContainer: {
    width: "1px", 
    margin: "0 auto"
  },
  testExtensionFooterMail: {
    textDecoration: 'underline',
    color: theme.palette.text.primary,
  },
  horizontalLogo: {
    paddingLeft:"20px", 
    paddingRight: "20px"
  },
  warning: {
    color: theme.palette.secondary.main,
  },
  logoutIcon: {
    color: `${theme.palette.text.primary}!important`,
  },
  successDialog: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.background.default,
    }
  }
}));
