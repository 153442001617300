// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// Owned
import Firefox from '../../Img/firefox.svg';
import Chrome from '../../Img/chrome.svg';
import Safari from '../../Img/safari.svg';

// External
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  paperContent: {
    margin: theme.spacing(2)
  },
  paper: {
    margin: theme.spacing(1),
    minHeight: 200,
    backgroundColor: `${theme.palette.text.primary}!important`,
  }
}));

function Install() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
      <Grid container>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} className={classes.paper}>
                <div className={classes.paperContent}>
                    <img src={Chrome} width="150" style={{marginTop: "10px"}} alt=""/>
                    <br />
                    <a target="_blank" rel="noreferrer" href="https://chrome.google.com/webstore/detail/super-agent/neooppigbkahgfdhbpbhcccgpimeaafi">
                        {t('account.install.chrome')}
                    </a>
                </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} className={classes.paper}>
                <div className={classes.paperContent}>
                    <img src={Firefox} width="150" style={{marginTop: "10px"}} alt=""/>
                    <br />
                    <a target="_blank" rel="noreferrer" href="https://addons.mozilla.org/en-GB/firefox/addon/super-agent/">
                    {t('account.install.firefox')}
                    </a>
                </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} className={classes.paper}>
                <div className={classes.paperContent}>
                    <img src={Safari} width="150" style={{marginTop: "10px"}} alt=""/>
                    <br />
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/super-agent-for-safari/id1568262835">
                    {t('account.install.safari')}
                    </a>
                </div>
            </Paper>
          </Grid>
      </Grid>
  )
}

export default Install;
