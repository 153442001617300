// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

// Owned
import { useStyles } from '../styles';

// External
import React from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { useTranslation } from 'react-i18next';

export default function ConfirmSignUp(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
        {t('account.confirm_sign_up.main_title')}
        </Typography>
        <form className={classes.form} noValidate onSubmit={props.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="email"
                variant="filled"
                required
                fullWidth
                id="email"
                label={t('account.confirm_sign_up.textfield.email')}
                autoFocus
                value={props.email}
                onChange={props.handleEmailChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                id="code"
                label={t('account.confirm_sign_up.textfield.code')}
                name="code"
                value={props.code}
                onChange={props.handleVCChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t('account.confirm_sign_up.button.submit')}
              </Button>
            </Grid>
            <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={props.resendCode}>
                  {t('account.confirm_sign_up.link.send_code')}
                  </Link>
                </Grid>
                <Grid item xs>
                    <Link href="#" variant="body2" onClick={ () => { props.changeAuthState( AuthState.SignIn )}}>
                    {t('account.confirm_sign_up.link.back_to_sign_in')}
                    </Link>
                </Grid>
            </Grid>
        </form>
      </div>
    </Container>
  );
}
