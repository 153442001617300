// External
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import ExtensionIcon from '@material-ui/icons/Extension';

// Owned
import { theme, useStyles } from '../styles';
import SuccessLogo from '../../Img/brand-success.png';
import { BrowserHelper } from '../Shared/BrowserHelper';

export default function CheckSuccess() {
    const { t } = useTranslation();
    const classes = useStyles(theme);

    const isMobileSafari = BrowserHelper.isMobileSafari(navigator.userAgent);

    return (
        <div className={classes.testExtensionContent}>
            <img src={SuccessLogo} width={30} alt="Success" />
            <Typography className={classes.testExtensionTitle} component="h2" variant="h5">
                { t('test_extension.success.title') }
            </Typography>
            <Typography className={classes.testExtensionSubtitle} component="h1" variant="h6">
            { t('test_extension.success.subtitle') }
            </Typography>
            { isMobileSafari &&
            <Toolbar className={classes.findExtensionSettingsToolbar}>
                <ExtensionIcon className={classes.findExtensionSettingsToolbarIcon} />
                <span className={classes.findExtensionSettingsToolbarText}>
                { t('test_extension.success.find_extension_helper') }
                </span>
            </Toolbar>
            }
        </div>
    )

}
