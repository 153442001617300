import BrandLogo from '../../Img/brand-logo.svg';
import IconDarkLogo from '../../Img/Icon-dark.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        height: '92px',
        padding: '32px',
        gap: '50px',
    },
}));

const BrandHeader = () => {
    const classes = useStyles()

    return (
        <div className={classes.header}>
            <img src={IconDarkLogo} width={40} alt="SuperAgent Logo"/>
            <img src={BrandLogo} width={185} alt="SuperAgent text logo"/>
            <div>&nbsp;</div>
        </div>
    );
}

export default BrandHeader
