//@ts-check
import { UAParser } from "ua-parser-js"

export class BrowserHelper {

    /**
     * @param {string} userAgent
     */
    static isMobileSafari(userAgent) {
        
        let browserName = this.browserName(userAgent);

        return browserName === "Mobile Safari";

    }

    static isiOS(userAgent) {

        let result = new UAParser().setUA(userAgent).getResult();
        return result.os.name === "iOS";

    }

    /**
     * @param {string} userAgent
     */
    static browserName(userAgent) {

        let result = new UAParser().setUA(userAgent).getResult();
        return result.browser.name;

    }

}