// Material UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// External
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';

const Menu = (props) => {
    const { t } = useTranslation();
    const classes = useStyles()

    return (
        <div>
            <List component="nav" aria-label="main mailbox folders">
                <ListItem component={Link} onClick={props.signOut} button>
                <ListItemIcon className={classes.logoutIcon}>
                    <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary={t('account.menu.menu_option.logout')} />
                </ListItem>
            </List>
        </div>
    );
};

export default Menu;
