// Owned
import SignIn from './SignIn';
import SignUp from './SignUp';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import ConfirmSignUp from './ConfirmSignUp';
import AuthAlerts from './AuthAlerts';
import ExtensionComm from '../Shared/ExtensionComm';

// Material UI
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

// Amplify
import { AuthState } from '@aws-amplify/ui-components';
import { Auth, Hub } from 'aws-amplify';

// External
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EmailValidator from 'email-validator';

Hub.listen('auth', ({ payload: { event, data } }) => {
    switch (event) {
        case 'signIn':
        case 'cognitoHostedUI':
            console.log("OAuth sign in")
            getUser().then(userData => console.log(userData));
            break;
        case 'signOut':
            // setUser(null);
            break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
            console.log('Sign in failure', data);
            break;
        default:
            break;
    }
});

function getUser() {
    return Auth.currentAuthenticatedUser()
      .then(userData => userData)
      .catch(() => console.log('Not signed in'));
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

function useQuery() {
    return new URLSearchParams(window.location.search);
}

const Authenticator = (props) => {
    const classes = useStyles();
    const [authState, setAuthState] = React.useState("");
    const [user, setUser] = React.useState();

    const fromExtensionQueryParam = useQuery().get('fromExtension');
    const fromOnboardQueryParam = useQuery().get('fromOnboard');
    const fromOptionsQueryParam = useQuery().get('options');

    const { t } = useTranslation();


    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then((userData) => {
                if (userData) {
                    setUser(userData);
                    changeAuthState(AuthState.SignedIn);
                }
            })
            .catch((e) => {
                var initialState = props.initialState;

                changeAuthState(initialState);
            });
    }, [props.initialState, fromExtensionQueryParam]);

    const [password, setPassword] = React.useState("");
    const [confirmPassword, setConfirmPassword] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [code, setCode] = React.useState();
    const [signingIn, setSigningIn] = React.useState(false);
    const [signingUp, setSigningUp] = React.useState(false);
    const [confirming, setConfirming] = React.useState(false);
    const [forgotPasswordBackdrop, setForgotPasswordBackdrop] = React.useState(false);

    const handlePasswordChange = (event) => {
        event.preventDefault();
        setPassword(event.target.value);
    }

    const handleConfirmPasswordChange = (event) => {
        event.preventDefault();
        setConfirmPassword(event.target.value);
    }

    const handleEmailChange = (event) => {
        event.preventDefault();
        setEmail(event.target.value.toLowerCase());
    }

    const handleVCChange = (event) => {
        event.preventDefault();
        setCode(event.target.value);
    }

    const handleSignIn = (event) => {
        event.preventDefault();

        setSigningIn(true);

        const username = email;

        Auth.signIn(username, password)
            .then((user) => {
                setUser(user);
                if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                    setPassword("");
                    changeAuthState(AuthState.ResetPassword);
                } else {
                    setSigningIn(false);
                    changeAuthState(AuthState.SignedIn);
                    props.setAuthenticated(true);
                    if (fromExtensionQueryParam === "true") {
                        ExtensionComm.sendCredentialsToExtension(
                            {
                                username: username,
                                password: password
                            },
                            (fromOptionsQueryParam === 'true'),
                            false,
                            props.queue
                        );
                    } else if (fromOnboardQueryParam === "true") {
                        ExtensionComm.sendCredentialsToExtension(
                            {
                                username: username,
                                password: password
                            },
                            (fromOptionsQueryParam === 'true'),
                            true,
                            props.queue
                        );
                    }
                }
            })
            .catch((e) => {
                setSigningIn(false);
                switch (e.code) {
                    case 'UserNotFoundException':
                    case 'NotAuthorizedException':
                        setError("Incorrect username/password.");
                        break;
                    case 'UserNotConfirmedException':
                        changeAuthState(AuthState.ConfirmSignUp);
                        resendCode();
                        break;
                    default:
                        break;
                }
            });
    }

    const handleSignUp = (event) => {
        event.preventDefault();

        setSigningUp(true);

        if (password !== confirmPassword) {
            setError(t('account.authenticator.error.passwords_not_match'));
            setSigningUp(false);
            return;
        }

        if (!EmailValidator.validate(email)) {
            setError(t('account.authenticator.error.invalid_email'));
            setSigningUp(false);
            return;
        }

        const username = email;

        Auth.signUp({
            username,
            email,
            password
        }).then(
            (user) => {
                setUser(user);
                event.preventDefault();
                setSigningUp(false);
                changeAuthState(AuthState.ConfirmSignUp);
            }
        ).catch(e => {
            event.preventDefault();

            setSigningUp(false);
            changeAuthState(AuthState.SignUp);

            switch (e.code) {
                case 'UsernameExistsException':
                    setError(t('account.authenticator.error.username_taken'));
                    break;
                case 'InvalidPasswordException':
                case 'InvalidParameterException':
                    setError(t('account.authenticator.error.password_rules'));
                    break;
                default:
                    break;
            }
        });
    };

    const confirmHelper = () => {
        changeAuthState(AuthState.SignIn);
        setConfirming(false);
    }

    const handleConfirm = (event) => {
        event.preventDefault();
        setConfirming(true);

        const username = email;

        Auth.confirmSignUp(username, code)
            .then(() => {
                setTimeout(confirmHelper, 2000);
            })
            .catch(
                (e) => {
                    setConfirming(false);
                    switch (e.code) {
                        case 'CodeMismatchException':
                        case 'UserNotFoundException':
                            setError(t('account.authenticator.error.wrong_user_pass'));
                            break;
                        default:
                            setError(t('account.authenticator.error.unknown_error'));
                    }
                    changeAuthState(AuthState.ConfirmSignUp);
                }
            );
    };

    const handleForgotPassword = (event) => {
        event.preventDefault();
        // Send Code
        setForgotPasswordBackdrop(true);

        const username = email;
        Auth.forgotPassword(username)
            .then((data) => {
                setForgotPasswordBackdrop(false);
                setSuccess(t('account.authenticator.success.code_sent'));
            })
            .catch((e) => {
                setForgotPasswordBackdrop(false);
                switch (e.code) {
                    case 'LimitExceededException':
                        setError(t('account.authenticator.error.too_many_attempts'));
                        break;
                    default:
                        setError(t('account.authenticator.error.double_check_username'));
                        break;
                }
            });
    }

    const handleSetNewPassword = (event) => {
        event.preventDefault();

        const username = email;

        setForgotPasswordBackdrop(true);

        if (password !== confirmPassword) {
            setError(t('account.authenticator.error.passwords_not_match'));
            setForgotPasswordBackdrop(false);
            return;
        }

        Auth.forgotPasswordSubmit(username, code, password)
            .then(() => {
                setForgotPasswordBackdrop(false);
                setSuccess(t('account.authenticator.success.password_changed'));

                changeAuthState(AuthState.SignIn);
                setConfirming(false);
            })
            .catch((e) => {
                setForgotPasswordBackdrop(false);
                switch (e.code) {
                    case 'InvalidPasswordException':
                    case 'InvalidParameterException':
                        setError(t('account.authenticator.error.password_rules'));
                        break;
                    case 'CodeMismatchException':
                        setError(t('account.authenticator.error.invalid_code'));
                        break;
                    case 'ExpiredCodeException':
                        setError(t('account.authenticator.error.request_code'));
                        break;
                    default:
                        setError(t('account.authenticator.error.unknown_error_long'));
                        break;
                }
            });
    }

    const handleResetPassword = (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            setError(t('account.authenticator.error.passwords_not_match'));
        } else {
            Auth.completeNewPassword(
                user,
                password
            ).then(user => {
                setUser(user);
                changeAuthState(AuthState.SignedIn);
                props.setAuthenticated(true);
            }).catch(e => {
                switch (e.code) {
                    case 'InvalidPasswordException':
                    case 'InvalidParameterException':
                        setError(t('account.authenticator.error.password_rules'));
                        break;
                    default:
                        break;
                }
            });
        }
    }

    const resendCode = () => {
        const username = email;

        Auth.resendSignUp(username)
            .then(() => {
                setSuccess("Code successfully sent.");
            })
            .catch(e => {
                switch (e.code) {
                    case "LimitExceededException":
                        setError(t('account.authenticator.error.too_many_attempts'));
                        break;
                    default:
                        break;
                }
            });
    }

    const signInWithApple = (event) => {
        event.preventDefault();
        
        Auth.federatedSignIn({
            provider: "SignInWithApple"
        });
    }

    const changeAuthState = (message) => {
        setAuthState(message);
    }

    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(null);

    switch (authState) {
        case AuthState.SignedIn:
            if (user) {
                return (
                    null
                );
            }
            else {
                changeAuthState(AuthState.SignIn);
                return null;
            }
        case AuthState.SignIn:
            return (
                <div>
                    <SignIn
                        handleEmailChange={handleEmailChange}
                        handlePasswordChange={handlePasswordChange}
                        changeAuthState={changeAuthState}
                        onSubmit={handleSignIn}
                        signInWithApple={signInWithApple}
                        email={email}
                        password={password}
                    />

                    <AuthAlerts
                        error={error}
                        setError={setError}
                        success={success}
                        setSuccess={setSuccess}
                    />

                    <Backdrop className={classes.backdrop} open={signingIn}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            );
        case AuthState.SignUp:
            return (
                <div>
                    <SignUp
                        handlePasswordChange={handlePasswordChange}
                        handleEmailChange={handleEmailChange}
                        changeAuthState={changeAuthState}
                        onSubmit={handleSignUp}
                        email={email}
                        password={password}
                        confirmPassword={confirmPassword}
                        handleConfirmPasswordChange={handleConfirmPasswordChange}
                    />
                    <AuthAlerts
                        error={error}
                        setError={setError}
                        success={success}
                        setSuccess={setSuccess}
                    />

                    <Backdrop className={classes.backdrop} open={signingUp}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            )
        case AuthState.ResetPassword:
            return (
                <div>
                    <ResetPassword
                        email={email}
                        password={password}
                        handlePasswordChange={handlePasswordChange}
                        handleConfirmPasswordChange={handleConfirmPasswordChange}
                        changeAuthState={changeAuthState}
                        handleResetPassword={handleResetPassword}
                    />
                    <AuthAlerts
                        error={error}
                        setError={setError}
                        success={success}
                        setSuccess={setSuccess}
                    />
                </div>
            )
        case AuthState.ForgotPassword:
            return (
                <div>
                    <ForgotPassword
                        handleEmailChange={handleEmailChange}
                        handlePasswordChange={handlePasswordChange}
                        handleVCChange={handleVCChange}
                        changeAuthState={changeAuthState}
                        onSubmit={handleSetNewPassword}
                        forgotPassword={handleForgotPassword}
                        confirmPassword={confirmPassword}
                        handleConfirmPasswordChange={handleConfirmPasswordChange}
                        email={email}
                        password={password}
                        code={code}
                    />
                    <AuthAlerts
                        error={error}
                        setError={setError}
                        success={success}
                        setSuccess={setSuccess}
                    />
                    <Backdrop className={classes.backdrop} open={forgotPasswordBackdrop}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            )
        case AuthState.ConfirmSignUp:
            return (
                <div>
                    <ConfirmSignUp
                        handleEmailChange={handleEmailChange}
                        handleVCChange={handleVCChange}
                        changeAuthState={changeAuthState}
                        onSubmit={handleConfirm}
                        email={email}
                        code={code}
                        resendCode={resendCode}
                    />
                    <AuthAlerts
                        error={error}
                        setError={setError}
                        success={success}
                        setSuccess={setSuccess}
                    />

                    <Backdrop className={classes.backdrop} open={confirming}>
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </div>
            )
        default:
            return (
                null
            )
    }
};

export default Authenticator;