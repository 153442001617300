// External
import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@material-ui/core/Typography';

// Owned
import { theme, useStyles } from '../styles';
import WarningLogo from '../../Img/brand-warning.png'

export default function CheckWarning(props) {

    const { t } = useTranslation();
    const classes = useStyles(theme);

    return (
        <div className={classes.testExtensionContent}>
            <img src={WarningLogo} width={30} alt="Warning" />
            <Typography className={`${classes.testExtensionTitle} ${classes.warning}`} component="h2" variant="h5">
                { t('test_extension.warning.title') }
            </Typography>
            <Typography className={classes.testExtensionSubtitle} style={{width: '294px'}}  component="h1" variant="h6">
                { t(props.subtitleKey) }
            </Typography>
        </div>
    )

}
