// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

// Owned
import { useStyles } from '../styles';

// External
import { AuthState } from '@aws-amplify/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ForgotPassword(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
        {t('account.forgot_password.main_title')}
        </Typography>
        <form className={classes.form} noValidate onSubmit={props.onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="email"
                variant="filled"
                required
                fullWidth
                id="email"
                label={t('account.forgot_password.textfield.email')}
                autoFocus
                value={props.email}
                onChange={props.handleEmailChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={props.forgotPassword}
              >
                  {t('account.forgot_password.button.send_code')}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                id="code"
                label={t('account.forgot_password.textfield.code')}
                name="code"
                value={props.code}
                onChange={props.handleVCChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                name="password"
                label={t('account.forgot_password.textfield.new_password')}
                type="password"
                id="password"
                autoComplete="current-password"
                value={props.password}
                onChange={props.handlePasswordChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                name="confirmPassword"
                label={t('account.forgot_password.textfield.confirm_password')}
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                value={props.confirmPassword}
                onChange={props.handleConfirmPasswordChange}
              />
            </Grid>

          </Grid>
          <Grid container spacing={2}></Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {t('account.forgot_password.button.confirm')}
            </Button>
          </Grid>
          <Button
              type="button"
              onClick={() => { props.changeAuthState( AuthState.SignIn )}}
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
          >
            {t('account.sign_in.button.login')}
          </Button>
      </form>
      </div>
    </Container>
  );
}
