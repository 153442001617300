export default class ExtensionCom {
    static async sendCredentialsToExtension(credentials, fromOptions, fromOnboard, queue) {
        const message = {
            messageType: "CREDS",
            credentials: credentials,
            fromOptions: fromOptions,
            fromOnboard: fromOnboard
        }

        try {
            document.dispatchEvent(new CustomEvent('superAgent_sdte', { detail: { message } }))
            // eslint-disable-next-line no-undef
            if (typeof sdte !== 'undefined') {
                // eslint-disable-next-line no-undef
                sdte(message);
            }
        } catch(e) {
            // Couldn't communicate directly, place message in queue
            queue.pushMessage(message);
        }
    }
}
