export default class MessageQueue {
    constructor() {
        this.messages = [];
    }

    pushMessage(message) {
        this.messages.push(message);
    }

    getMessage() {
        return this.messages.pop();
    }
}