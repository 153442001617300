// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

// Owned
import { useStyles } from '../styles';

// External
import React from 'react';
import { AuthState } from '@aws-amplify/ui-components';
import { useTranslation } from 'react-i18next';

export default function SignIn(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <Grid container component="main" className={classes.root}>
        <Grid item xs={12} sm={12} md={12} elevation={6}>
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              {t('account.sign_in.main_title')}
            </Typography>
            <form className={classes.form} noValidate onSubmit={props.onSubmit}>
              <TextField
                variant="filled"
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('account.sign_in.textfield.email')}
                name="email"
                autoFocus
                value={props.email}
                onChange={props.handleEmailChange}
              />
              <TextField
                variant="filled"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t('account.sign_in.textfield.password')}
                type="password"
                id="password"
                autoComplete="current-password"
                value={props.password}
                onChange={props.handlePasswordChange}
              />

              <Button
                id="submitLogin"
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t('account.sign_in.button.login')}
              </Button>

              <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={() => {props.changeAuthState(AuthState.SignUp)}}
              >
                {t('account.sign_up.button.sign_up')}
              </Button>

              <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={() => {props.changeAuthState(AuthState.ForgotPassword)}}
              >
                {t('account.sign_in.link.forget_password')}
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
