const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_iv6CkI7wU",
    "aws_cognito_identity_pool_id": "eu-west-1:440e7f2b-90fd-4d33-9d12-5196f57ad346",
    "aws_user_pools_web_client_id": "1490es0lrupfegbui6tbhn8fdt",
    "aws_appsync_graphqlEndpoint": "https://pigjwerg2bhf5oqucbjnd6oatq.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "magicmakerstack-magicstorage584335d1-1twqrtirhswh6",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "magic",
            "endpoint": "https://dfm14g4e2d.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "logger",
            "endpoint": "https://y0bh5wmgve.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "keys",
            "endpoint": "https://kmik8r3h40.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        },
        {
            "name": "reporter",
            "endpoint": "https://53ayhhadge.execute-api.eu-west-1.amazonaws.com/prod",
            "region": "eu-west-1"
        }
    ],
    "oauth": {
        "domain": 'super-agent-test.auth.eu-west-1.amazoncognito.com',
        "scope": ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        "redirectSignIn": 'https://dev.super-agent.com',
        "redirectSignOut": 'https://dev.super-agent.com',
        "responseType": 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
};

export default awsmobile;