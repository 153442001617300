// Material UI
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

// Owned
import { useStyles } from '../styles';

// External
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function ResetPassword(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
        {t('account.reset_password.main_title')}
        </Typography>
        <form className={classes.form} noValidate onSubmit={props.handleResetPassword}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="username"
                variant="filled"
                required
                fullWidth
                id="username"
                label={t('account.reset_password.textfield.email')}
                value={props.username}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                autoFocus
                name="password"
                label={t('account.reset_password.textfield.new_password')}
                type="password"
                id="password"
                value={props.password}
                onChange={props.handlePasswordChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                required
                fullWidth
                name="confirmPassword"
                label={t('account.reset_password.textfield.confirm_password')}
                type="password"
                id="confirmPassword"
                value={props.confirmPassword}
                onChange={props.handleConfirmPasswordChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                {t('account.reset_password.button.confirm')}
              </Button>
            </Grid>
        </form>
      </div>
    </Container>
  );
}
