// Material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Box from '@material-ui/core/Box';

// Owned
import '../../App.css';

// External
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    
    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <div >
            <Dialog fullScreen open={props.open} TransitionComponent={Transition} className={classes.successDialog}>
                <Box fontWeight="fontWeightBold" style={{ position: 'absolute', left: '50%', top: '50%',transform: 'translate(-50%, -50%)', textAlign:'center'}}>
                    <CheckCircleIcon color="primary" fontSize="large" />
                    <Typography variant="h4" component="h3">{t('account.extension_auth_success.title')}</Typography>
                    <Typography variant="h6" component="h3" style={{ marginTop: 10 }}>{t('account.extension_auth_success.subtitle')}</Typography>
                    <Button variant="contained" color="primary" onClick={handleClose} style={{ marginTop: 15 }}>{t('general.button.dismiss')}</Button>
                </Box>
            </Dialog>
        </div>
    );
}
