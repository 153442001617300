// Material UI
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

// Owned
import Wrapper from './Components/Wrapper';
import Menu from './Components/Menu/Menu';
import Install from './Components/Install/Install';
import ExtensionAuthSuccess from './Components/ExtensionAuth/ExtensionAuthSuccess';


// External
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import './i18n';

Amplify.configure(awsconfig);
/*
Amplify.configure({
  Auth: {
    "oauth": {
      "AppClientId": "1490es0lrupfegbui6tbhn8fdt",
      "domain": 'super-agent-test.auth.eu-west-1.amazoncognito.com',
      "scope": ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      "redirectSignIn": 'https://account.super-agent.com/',
      "redirectSignOut": 'https://account.super-agent.com',
      "responseType": 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
});
*/

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    marginLeft: 40
  },
  content: {
    marginTop: 30
  },
}));

function useQuery() {
  return new URLSearchParams(window.location.search);
}

function App(props) {
  const classes = useStyles();
  const [openExtensionAuthSuccess, setOpenExtensionAuthSuccess] = React.useState(false);

  const fromExtensionQueryParam = useQuery().get('fromExtension');
  const fromOnboardQueryParam = useQuery().get('fromOnboard');

  useEffect(() => {
    if (fromExtensionQueryParam === 'true' || fromOnboardQueryParam === 'true') {
      setOpenExtensionAuthSuccess(true);
    }
  }, [fromExtensionQueryParam, fromOnboardQueryParam]);

  return (
      <div>
        <div className="App">
          <CssBaseline />
          <div className={classes.content}>
            <Container>
              <Router>
                <Grid container spacing={5}>
                  <Grid item xs={3}>
                    <Menu signOut={props.signOut} />
                  </Grid>
                  <Grid item xs={9}>
                    <div>
                      <Switch>
                        <Route path="/">
                          <Install />
                        </Route>
                        <Route path="/install">
                          <Install />
                        </Route>
                      </Switch>
                    </div>
                  </Grid>
                </Grid>
              </Router>
              <ExtensionAuthSuccess open={openExtensionAuthSuccess} setOpen={setOpenExtensionAuthSuccess} />
            </Container>
          </div>
        </div>
      </div>
  );
}

export default Wrapper(App);
