import { DialogTitle } from '@material-ui/core';
import { useCallback, useState } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

export const CheckFakeDialog = ({ close }) => {
    const [open, setOpen] = useState(true)
    const onClose = useCallback(() => {
        setOpen(false)
        close()
    }, [close])

    if (!open) {
        return null
    }

    return (
        <Box aria-labelledby="check-dialog-title" id="check-test-dialog" hidden>
            <DialogTitle id="check-dialog-title">Test Cookie Notification</DialogTitle>
            <Button id="check-dialog-close" onClick={onClose}>Close</Button>
        </Box>
    );
}
